import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized): void => {
  if (process.client) {
    if (to.query.theme === 'v2') {
      document.body.classList.add('lh-roomie-v2')
    } else {
      document.body.classList.remove('lh-roomie-v2')
    }
  }
})
